@import '~styles/colors';

.MiniCalendar__container {
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //align-self: stretch;
  padding: 0;
  margin: 10px 0px 10px 0px;
  //margin: 0;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.MiniCalendarHeader__col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
