@import '~styles/colors.scss';

$HEADER_HEIGHT_DESKTOP: 48px;
$HEADER_HEIGHT_MOBILE: 56px;

.HeaderModal__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $HEADER_HEIGHT_DESKTOP;
  z-index: 2;
  background-color: $SURFACE_LIGHT;
  animation-name: expand-header;
  animation-duration: 0.3s;
  min-height: 50px;
  @media (min-width: 750px) {
    border-radius: 32px 0 0 0;
    //border-bottom: solid 0.6px $BORDER_GRAY_LIGHT;
  }
  @media (max-width: 750px) {
    display: flex;
    top: 0;
    left: 0;
    //z-index: 60;
    flex-direction: column;
    height: $HEADER_HEIGHT_MOBILE;
    padding: 0;
  }

  &-dark {
    @extend .HeaderModal__wrapper;
    background-color: $GRAY_900;
  }
}

.with-shadow {
  display: flex;
  box-shadow: inset 0px -1px 0px $BORDER_GRAY_LIGHT;
  background-color: $SURFACE_LIGHT;
}

.HeaderModal__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  @media (min-width: 750px) {
    padding-left: 0;
  }

  &-shadow {
    @extend .HeaderModal__row;
    border-bottom: 0.5px solid $GRAY_300;

    &-dark {
      @extend .HeaderModal__row-shadow;
      background-color: $GRAY_900;
    }
  }
}
.HeaderModal__icon-left {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: flex-start;
}
.HeaderModal__title-button {
  max-width: 60%;
  border-radius: 24px !important;
  padding: 8px 24px 8px 24px !important;

  &-visible {
    @extend .HeaderModal__title-button;
    display: flex !important;
    opacity: 1;
    visibility: visible !important;
  }
}

.HeaderModal__container--title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
}
.HeaderModal__title {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 500;

  @media (min-width: 750px) {
    margin-left: 32px;
  }

  &-dark {
    @extend .HeaderModal__title;
    color: $ICON_GRAY_DARK;
  }
}

.HeaderModal__container--icons {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
}

.HeaderModal__icon {
  width: 20px;
  height: 20px;
  fill: $GRAY_700;

  &-dark {
    @extend .HeaderModal__icon;
    fill: $GRAY_200;
  }
}
