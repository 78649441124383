@import '~styles/colors.scss';

.Calendar_container {
  display: flex;
  width: 100%;
  min-height: 100%;
  //border-radius: 12px;
    background-color: white;
  flex-direction: column;

  &-dark {
    @extend .Calendar_container;
    background: $GRAY_900;
  }
  &-collapsed {
    @extend .Calendar_container;
    //margin-right: 230px;
    //width: calc(100% - 200px);
    @media (min-width: 750px){
      //max-width: calc(100% - 300px);
    }

    &-dark {
      @extend .Calendar_container-collapsed;
      background: $GRAY_900 ;
    }

    //animation: calendar_collapse 300ms;
    //@keyframes calendar_collapse {
    //  from {
    //    width: calc(100% - 200px);
    //  }
    //  to {
    //    width: 100%;
    //  }
    //}
  }
}

.Kalend__Event__summary__type-normal {
  //font-size: 0.7rem !important;
  padding: 1px 0px 6px 4px !important;
}

.Kalend__CalendarHeaderCol {
  padding-bottom: 2px !important;
  width: 100% !important;
}

.Kalend__WeekNumbersCol__text {
  font-size: 0.7rem !important;
}

.Kalend__Agenda__container {
  max-width: 800px;
  margin: 0 auto;
}

.Kalend__CalendarHeaderCol-dark {
  @extend .Kalend__CalendarHeaderCol;
  background: $GRAY_900 !important;
}
.Kalend__CalendarHeader-tall-dark {
  background: $GRAY_900 !important;
}

.Kalend__CalendarHeaderWeekDays__text {
  }
