@import '~styles/colors';

.SettingsCard__container {
  width: 100%;
  margin-bottom: 16px;
  max-width: 700px;
  height: 100%;
  overflow: hidden;
}

.SettingsCard__title {
  color: $GRAY_600;
  font-size: 2.2rem;
  padding:0;
  margin:0;
  padding-bottom: 16px;

  &-dark {
    @extend .SettingsCard__title;
    color: $GRAY_300;
  }
}

