@import '~styles/colors';

.SettingsContent__container {
  height: 100%;
  width: 100%;
  padding-left: 32px;
  padding-right: 16px;
  //max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
