@import '~styles/colors';

.EventDetailDates {
  display: flex;
  flex-direction: column;

  @media (min-width: 750px) {
    flex-direction: row;
  }
}
