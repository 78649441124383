@import '../../styles/colors';

$PADDING:24px;

.ModalNew__wrapper {
  position: relative;
}
.ModalNew__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(12, 12, 12, 0.09);
  justify-content: center;
  align-items: center;
  display: flex;

  &-dark {
    @extend .ModalNew__backdrop;
    background-color: rgba(12, 12, 12, 0.6);
  }
}

.ModalNew__wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  //width: 100%;
  height: auto;
  z-index: 20;
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 8px;

  &-dark {
    @extend .ModalNew__wrapper;
    box-shadow: none;
    background: $GRAY_900;
  }
}

.ModalNew__container-outer {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 80vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: $PADDING;
}

.ModalNew__container-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-left: $PADDING;
  padding-right: $PADDING;
}

.ModalNew__container-outer--with-header {
  @extend .ModalNew__container-outer;
  padding-top: 2px;
}

.EditEventModal {
  @extend .ModalNew__wrapper;
  width: 500px;
}

.SettingsModal {
  @extend .ModalNew__wrapper;
  min-width: 800px;
  height: 70vh;
}

.ModalNew__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  padding-right: $PADDING;
}

.ModalNew__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: $PADDING;

  &-empty {
    @extend .ModalNew__footer;
    padding: 0;
    padding-bottom: $PADDING;
  }
}

.ModalNew__icon {
  width: 30px;
  height: 30px;
  fill: $GRAY_600;

  &-dark {
    @extend .ModalNew__icon;
    fill: $GRAY_400;
  }
}
