@import '~styles/colors';

.ButtonBase {
  min-height: 20px;
  font-size: 1.6rem;
  font-family: Poppins, serif;
  border-radius: 10px;
  //padding: 12px 16px 12px 16px;
  font-weight: 400;
  border: none;
  outline-style: none;
  cursor: pointer;
  color: $GRAY_800;
  background-color: transparent;
  position: relative;
  overflow: hidden;

  &:hover {
    //@extend .ButtonBase;
    background-color: $GRAY_200;
  }

  &-normal {
    @extend .ButtonBase;
    border: none;
    background-color: $GRAY_800;
    padding: 4px 12px 4px 12px;
    color: $GRAY_100;

    &:hover {
      @extend .ButtonBase-normal;
      background-color: $GRAY_600;
    }
  }

  &-disabled {
    @extend .ButtonBase;
    background-color: $GRAY_400;
    color: $GRAY_700;
  }

  &-primary {
    @extend .ButtonBase;
    padding: 4px 12px 4px 12px;
    background-color: $PRIMARY_LIGHT;
    color: $GRAY_200;

    &:hover {
      @extend .ButtonBase-primary;
      background-color: $PRIMARY_LIGHT_OVERLAY;
    }

    &-dark {
      @extend .ButtonBase-primary;
      background-color: $PRIMARY_DARK;
      color: $GRAY_800;
    }
  }

  &-transparent {
    @extend .ButtonBase;
    padding: 4px 12px 4px 12px;
    color: $GRAY_700;
    background-color: transparent;

    &:hover {
      //@extend .ButtonBase-transparent;
      background-color: $GRAY_200;
    }
  }
}

.ButtonBase__animation {
  position: absolute;
  transform: scale(0);
  background-color: rgba(50, 50, 50, 0.3);
  border-radius: 50%;
  animation: ripple__animation 500ms ease-in;

  @keyframes ripple__animation {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
}

.ButtonBase__loader-circle {
  width: 12px;
  height: 12px;
  background-color: $GRAY_300;
  border-radius: 6px;
  margin: 0 6px 0 6px;
  opacity: 0;
  animation-iteration-count: infinite;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-name: ButtonBase__animation-pulse-loader;
  animation-delay: 0s;

  &-left {
    @extend .ButtonBase__loader-circle;
    animation-delay: 0s;
  }

  &-middle {
    @extend .ButtonBase__loader-circle;
    animation-delay: 0.3s;
  }

  &-right {
    @extend .ButtonBase__loader-circle;
    animation-delay: 0.5s;
  }
}

@keyframes ButtonBase__animation-pulse-loader {
  0% {
    opacity: 0;
    background-color: $GRAY_500;
  }
  50% {
    opacity: 1;
    background-color: $GRAY_300;
  }
  100% {
    opacity: 0;
  }
}

.Button-long {
  width: 100%;
  padding: 4px 12px 4px 12px;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    @extend .Button-long;
  }
}
