@import '~styles/colors';

.SettingsRow__container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  align-items: center;
}

.SettingsRow__child {
  width: 50%;
}

.SettingsRow__title {
  color: $GRAY_800;
  font-size: 1.2rem;
  padding:0;
  margin:0;

  &-dark {
    @extend .SettingsRow__title;
    color: $GRAY_200;
  }
}

