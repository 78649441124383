@import 'styles/colors';

html, body {
  width: 100vw !important;
  height: 100vh !important;
  margin: 0;
  padding: 0;
  font-size: 14px !important;
  //background-color: #afaaaa;

  @media (max-width: 750px) {
    overscroll-behavior-y: contain; /* disable pull to refresh */
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  @media screen and (max-width: 360px) {
    font-size: 15px !important;
  }
  @media screen and (max-width: 380px) {
    font-size: 14px!important;
  }
  @media screen and (max-width: 415px) {
    font-size: 16px!important;
  }
  @media (min-width: 1600px) {
    font-size: 14px!important;
  }
  @media (max-width: 750px) {
    background-color: transparent;
    //pointer-events: none; // for Android

    -webkit-touch-callout: none; // for iOS

    -webkit-user-select: none;

    -html-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}

.full-screen {
  width: 100%;
  height: 100%;
}

#root {
  width: 100vw;
  height: 100vh;
}

.root-wrapper {
  width: 100vw;
  height: 100vh;
  //background-color: #243042;
}


// Chakra
.Chakra__modal-padding-24 {
  padding: 24px !important;
}
.Chakra__modal-padding-2 {
  padding: 2px !important;
}

.full-height {
  height: 100%;
}

.Surface {
  width: 100%;
  height: 100%;
  background-color: $SURFACE_LIGHT;

  &-dark {
    @extend .Surface;
    background-color: $SURFACE_DARK;
  }
}

.color-primary {
  color: $PRIMARY_LIGHT;
}
.color-primary-dark {
  color: $PRIMARY_DARK;
}
.bg-primary {
  background: $PRIMARY_LIGHT;
}
.bg-primary-dark {
  background: $PRIMARY_DARK;
}
.color-base {
  color: $GRAY_900;
}
.color-base-dark {
  color: $GRAY_100;
}
.color-base {
  color: $GRAY_900;
}
.bg-base {
  color: $GRAY_100;
}
.bg-base-dark {
  color: $GRAY_900;
}

.PrimaryButton {
  background: $PRIMARY_LIGHT;
  color: $GRAY_100;

  &-dark {
    @extend .PrimaryButton;
    background: $PRIMARY_DARK;
    color: $GRAY_900;
  }
}
.SecondaryButton {
  background: $GRAY_900;
  color: $GRAY_100;

  &-dark {
    @extend .SecondaryButton;
    background: $GRAY_300;
    color: $GRAY_900;
  }
}
