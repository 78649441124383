@import '~styles/colors';

.event-view__container {
  display: flex;
  flex-direction: column;
  padding: 8px 24px 0 24px !important;
}

.event-view__container-no-icon {
  @extend .event-view__container;
  margin-left: 32px;
}

.event-view__text-title {
  font-size: 2.6rem;
  font-weight: 600;
  color: $GRAY_900;
  margin: 0;
  padding: 0;
  margin-top: 8px;
}

.event-view__text-date {
  font-size: 1.4rem;
  color: $GRAY_800;
}

.event-view__text-hour {
  font-size: 1.2rem;
  color: $GRAY_700;
}

.event-view__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.event-view__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.text-normal {
  font-size: 1.3rem;
  color: $GRAY_800;
}
.text-small {
  font-size: 1.1rem;
  color: $GRAY_600;
}


.EventView__header-icon {
  height: 40px;
  width: 40px;
  fill: $GRAY_300;
}
