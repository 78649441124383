@import '~styles/colors.scss';

.CalendarDrawer__wrapper {
  flex: 300px 0 0;
  display: flex;
  flex-direction: column;
  //padding: 12px 8px 12px 8px;
  //box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.04),
  //0px 5px 5px rgba(0, 0, 0, 0.04), 0px 10px 5px rgba(0, 0, 0, 0.07),
  //0px 2px 5px rgba(0, 0, 0, 0.11);
  //padding: 32px 16px 24px 16px;
  justify-content: center;
  position: relative;
    //width: 230px;
    justify-content: flex-start;
    //border-right: solid 0.5px $GRAY_300;
    //margin-right: 10px;


  //@media (min-width: 750px) and (max-width: 960px) {
  //  border-right: none;
  //}
}

.Drawer__expand {
  animation: drawer_expand 500ms ease-in;
  @keyframes drawer_expand {
    from {
      display: none;
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 300px;
    }
  }
}
.Drawer__collapse {
  animation: drawer_collapse 500ms;
  @keyframes drawer_collapse {
    from {
      opacity: 1;
      width: 300px;
    }
    to {
      opacity: 0;
      width: 0;
    }
  }
}

.CalendarDrawer__wrapper-dark {
  @extend .CalendarDrawer__wrapper;
  background-color: $GRAY_900;
  border-right: solid 0.5px $MODAL_DARK;
}

.Drawer__content {
  padding: 8px;
  min-width: 300px;
}

.Drawer__container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
}

.drawer-icon {
  width: 20px !important;
  margin-right: 14px;
  font-family: 'Poppins', sans-serif;

  //@media (max-width: 750px) {
  //  width: 10px !important;
  //  height: 10px !important;
  //}
}
.drawer-icon-dark {
  @extend .drawer-icon;
  fill: $GRAY_400;
}

.CalendarDrawer__container-subtitle {
  display: flex;
  flex-direction: row;
  height: 14px;
  align-items: center;
}
.CalendarDrawer__subtitle {
  font-size: 1.2rem;
  margin: 16px;
  width: 100%;
  font-weight: 600;
}
.CalendarDrawer__subtitle-dark {
  @extend .CalendarDrawer__subtitle;
  color: $GRAY_700;
}

.CalendarDrawer__calendar-title {
  font-size: 1.2rem;
  margin-left: 20px !important;
  margin-bottom: 4px;
}

.CalendarDrawer__container-section {
  margin-bottom: 20px;
}
