@import '~styles/colors';

.Spinner-icon {
  width: 50px;
  height: 50px;

  &-dark {
    @extend .Spinner-icon;
    fill: $GRAY_100;
  }
}

.rotate {
  -webkit-animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
