@import '~styles/colors';

.ButtonIcon__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;

  &-small {
    @extend .ButtonIcon__container;
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  &-big {
    @extend .ButtonIcon__container;
    width: 55px;
    height: 55px;
    border-radius: 27.5px;
  }

  &-full {
    @extend .ButtonIcon__container;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.ButtonIcon {
  fill: $GRAY_600;
  background-color: transparent;
  font-size: 1.4rem;
  border: none;
  outline-style: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 27.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &:hover {
    @extend .ButtonIcon;
    opacity: 0.9;
    background-color: $GRAY_300;
  }

  &-dark {
    @extend .ButtonIcon;
    fill: $GRAY_400;
  }

  &-inactive {
    @extend .ButtonIcon;

    &:active {
      @extend .ButtonIcon-inactive;
      background-color: transparent;
      animation: none;
    }

    &:hover {
      @extend .ButtonIcon-inactive;
      background-color: transparent;
    }
  }
}

.ButtonIcon__disabled {
  cursor: default !important;
  &:hover {
    background-color: transparent !important;
  }
  &:active {
    background-color: transparent !important;
  }
}

.ButtonIcon__svg {
  width: 100%;
  height: 100%;
  padding: 1px;
  z-index: 5;
  fill: $GRAY_600;

  &-normal {
    @extend .ButtonIcon__svg;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.ButtonIcon__backdrop {
  position: absolute;
  background-color: $GRAY_400;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation-name: ButtonIcon__animation-backdrop;
  animation-delay: 0s;
  animation-duration: 0.4s;

  &:active {
    @extend .ButtonIcon__backdrop;
    background-color: $GRAY_600;
    animation-name: ButtonIcon__animation-press;
    animation-duration: 0.1s;
    animation-delay: 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }
}

@keyframes ButtonIcon__animation-press {
  0% {
    background-color: $GRAY_100;
    opacity: 0.5;
  }
  100% {
    background-color: $GRAY_300;
    opacity: 1;
  }
}

@keyframes ButtonIcon__animation-backdrop {
  0% {
    background-color: $GRAY_100;
    width: 0;
    height: 0;
  }
  100% {
    background-color: $GRAY_400;
    width: 100%;
    height: 100%;
  }
}
