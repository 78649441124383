@import '../../styles/colors';

.Modal__wrapper {
  position: relative;
}
.Modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(12, 12, 12, 0.09);
}

.Modal__container {
  position: absolute;
  display: flex;
  flex-direction: column;
  //width: 100%;
  height: auto;
  padding: 16px;
  padding-left: 4px;
  z-index: 20;
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 8px;

  &-dark {
    @extend .Modal__container;
    box-shadow: none;
    background: $GRAY_900;
  }
}

.Modal__container-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

