@import '~styles/colors';

.MiniCalendarDay__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &:hover {
    @extend .MiniCalendarDay__button;
  }

  &-dark {
    @extend .MiniCalendarDay__button;
    color: $GRAY_100 !important;

    &:hover {
      @extend .MiniCalendarDay__button-dark;
      background-color: $GRAY_700;
    }
  }

  &-selected {
    @extend .MiniCalendarDay__button;
    background-color: $PRIMARY_LIGHT;
    color: $primaryText;

    &:hover {
      @extend .MiniCalendarDay__button-selected;
      background-color: $PRIMARY_LIGHT;
    }

    &-dark {
      @extend .MiniCalendarDay__button-selected;
      background-color: $PRIMARY_DARK !important;
      color: $GRAY_800 !important;
    }
  }
}

.MiniCalendarDay__text {
  font-size: 1rem !important;
  color: $GRAY_400;

  &-dark {
    @extend .MiniCalendarDay__text;
    color: $GRAY_600;
  }

  &-normal {
    @extend .MiniCalendarDay__text;
    color: $GRAY_700;

    &-dark {
      @extend .MiniCalendarDay__text-normal;
      color: $textDark;
    }

    &-selected {
      @extend .MiniCalendarDay__text-normal;
      color: $reverseText;

      &-dark {
        @extend .MiniCalendarDay__text-normal-selected;
        color: $text;
      }
    }
  }

  &-selected {
    @extend .MiniCalendarDay__text;
    color: $reverseText;
  }
}
