@import '~styles/colors';

.TimePickerUnit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $GRAY_600;
  text-align: center;
  //@media (min-width: 750px) {
    font-size: 1.1rem;
    padding: 4px 8px 4px 8px !important;
  //}
  //@media (max-width: 750px) {
  //  font-size: 1.8rem;
  //  padding: 4px 8px 4px 8px;
  //}


  margin-top: 1px;
  margin-bottom: 1px;
  &:hover {
    @extend .TimePickerUnit;
    padding: 4px 8px 4px 8px;
    //background-color: $GRAY_200;
    border-radius: 0;
  }

  &-dark {
    @extend .TimePickerUnit;
    color: $GRAY_300;

    &:hover {
      @extend .TimePickerUnit-dark;
      padding: 4px 8px 4px 8px;
      background-color: $GRAY_700;
      border-radius: 0;
    }
  }
  &-selected {
    @extend .TimePickerUnit;

    &-dark {
      @extend .TimePickerUnit-selected;
    }
  }
}
//
//
//.time-picker__text-dark {
//  @extend .time-picker__text;
//  color: $GRAY_400 !important;
//}
//.time-picker__text--selected {
//  @extend .time-picker__text;
//  font-weight: bold;
//  color: $PRIMARY_LIGHT !important;
//}
//.time-picker__text--selected-dark {
//  @extend .time-picker__text--selected;
//  color: $PRIMARY_DARK !important;
//}
//.time-picker__text--hidden {
//  @extend .time-picker__text;
//  color: transparent !important;
//}
