@import '~styles/colors.scss';

.MobileNavbar__wrapper {
  //width: 100%;
  height: 80px;
  //border-top: solid 0.4px $GRAY_200;
  align-items: flex-start;
  width: 100%;
  display: flex;
  justify-content: center;

  &-dark {
    @extend .MobileNavbar__wrapper;
    background: #1D1F26;
  }
}

.MobileNavbar__container {
  //width: 100%;
  height: 55px;
  //border-top: solid 0.4px $GRAY_200;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  background: #ededed;
  border-radius: 20px;
  //display: flex;

  &-dark {
    @extend .MobileNavbar__container;
    background: $GRAY_900;
  }
}

.MobileNavbar-left {
  display: flex;
  width: 100px;
}

.MobileNavbar-right {
  display: flex;
  flex-grow: 1;
}

.MobileNavbar__icon {
  width: 25px;
  height: 25px;
  fill: $GRAY_800;

  &-dark {
    @extend .MobileNavbar__icon;
    fill: $GRAY_200;
  }
}
