@import '~styles/colors.scss';

.DrawerItem__button {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  //padding: 8px !important;
  margin: 0 !important;
  padding-left: 14px !important;
  border-radius: 8px !important;
}
.DrawerItem__button-dark {
  @extend .DrawerItem__button;
}
.DrawerItem__button-selected {
  @extend .DrawerItem__button;
  background: $PRIMARY_LIGHT_OVERLAY !important;
}
.DrawerItem__button-selected-dark {
  @extend .DrawerItem__button-selected;
  background: $PRIMARY_DARK_OVERLAY !important;
}
.DrawerItem__button-text {
  font-size: 1.1rem;
  color: $GRAY_700;
  margin: 0;
  padding: 0;
  text-wrap: normal;
  overflow: hidden;
  padding-left: 14px !important;
}
.DrawerItem__button-text-dark {
  @extend .DrawerItem__button-text;
  color: $GRAY_400;
}
