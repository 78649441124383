.ButtonBase-down {
  animation: ButtonBase-down-animate 200ms;
  transform: scale(0.95);

  @keyframes ButtonBase-down-animate {
    from {
    transform: scale(1)
    }
    to {
    transform: scale(0.95)
    }
  }
}

.ButtonBase-up {
  animation: ButtonBase-up-animate 200ms;
  transform: scale(1);

  @keyframes ButtonBase-up-animate {
    from {
      transform: scale(0.95)
    }
    to {
      transform: scale(1)
    }
  }
}
