@import '~styles/colors';

.SettingsMenu__wrapper {
  height: 100%;
  min-width: 200px;
  padding: 0 8px 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 750px) {
    width: 100%;
    align-items: flex-start;
  }
}

.SettingsMenu__button {
  width: 80px;
  padding: 4px;
}

.SettingsMenu__icon {
  width: 20px;
  height: 20px;
  fill: $GRAY_700;

  &-dark {
    @extend .SettingsMenu__icon;
    fill: $GRAY_300;
  }
}

.SettingsMenu__icon-red {
  width: 20px;
  height: 20px;
  fill: $PRIMARY_LIGHT;

  &-dark {
    @extend .SettingsMenu__icon-red;
    fill: $PRIMARY_DARK;
  }
}
